import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@tiffinger-thiel/share-target';
import 'probonio-shared-ui/module/error/sentry';
import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from './component/snackbar';
import './index.css';
import './lang';

function checkLocalStorageSupport() {
  try {
    if (!window.localStorage) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
}

const root = createRoot(document.getElementById('root')!);

if (!checkLocalStorageSupport()) {
  root.render(<div>Bitte erteilen Sie unserer App Zugriff auf Ihren LocalStorage, um die korrekte Funktionalität zu gewährleisten.</div>);
} else {
  const App = lazy(() => import('./App'));
  root.render(
    <SnackbarProvider>
      <App />
    </SnackbarProvider>,
  );
}
